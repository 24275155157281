<template>
  <div class="inner-layout-800">

    <div class="detailP-subject-box">
      <div class="category">이벤트</div>
      <div class="heading-1">{{ item.title }}</div>
    </div>

    <div class="detailP-thumb">
      <image-alt :src="item.src" altType="detail"/>
    </div>

    <div v-html="item.content" class="detailP-content"/>

    <div class="horizon-bar mb-60"></div>

    <comment ref="comments" :cateCode="40" :count="item.comment"/>

  </div>
</template>

<script>
export default {
  name: "event",
  data: function () {
    return {
      idx: this.$route.params.idx,
      item: {},
    }
  },
  created() {
    this.setItem();
  },
  methods: {
    async setItem() {
      if (!this.idx) await this.$router.push('/event');
      const { result, data } = await this.$api.getEventDetail({ idx: this.idx })
      if (result === 'success') this.item = data
    },
  }
}
</script>
